import createCache from "@emotion/cache";
import flagsmith from "flagsmith";
import { __setApplicationConfiguration as setApplicationConfiguration } from "./application-configuration";
import { identifyFeatureFlagUser } from "./feature-flags";
import { setupThirdPartyScripts } from "./scripts/script-provider";
import { applicationSettingsStore } from "./store/settings-store";
import { ApplicationConfiguration } from "./types";

export { apiConfig } from "./api-configuration/api-configuration";
export { applicationConfig, __setApplicationConfiguration } from "./application-configuration";
export * from "./auth/auth-storage";
export * from "./auth/auth.hooks";
export * from "./auth/local-storage.utils";
export * from "./auth/utils";
export { getLocalFeatureFlags, hasFeature, localFeatureFlagsStorageKey } from "./feature-flags";
export type { FeatureFlagsMap } from "./feature-flags";
export * from "./logging/logging";
export * from "./queryClient";
export * from "./scripts";
export * from "./store";
export * from "./types";

function getNonce() {
    let nonce = undefined;
    if (globalThis.document) {
        const scripts = globalThis.document.getElementsByTagName("script");
        for (let i = 0, length = scripts.length; i < length; i++) {
            nonce = scripts[i].nonce;
            if (nonce) {
                break;
            }
        }
    }
    return nonce;
}

/**
 * This should be called ONCE by the adapt host.
 * After that, updates to the authentication context, theme, and
 * api configuration will be reflected in the applicationSettingsStore.
 *
 * @param applicationConfig The application configuration that was loaded by the adapt host.
 * @param initI18n The function to initialize i18n.
 * @returns The settings used by applications
 */
export async function init(
    applicationConfig: ApplicationConfiguration,
    initI18n: (applicationConfig: ApplicationConfiguration) => Promise<void>,
) {
    // Save the configuration for others to use
    setApplicationConfiguration(applicationConfig);

    applicationSettingsStore.getState().setApplicationConfiguration(applicationConfig);
    await flagsmith.init({
        cacheFlags: true,
        cacheOptions: {
            skipAPI: true,
            ttl: 5 * 60 * 1000,
        },
        environmentID: applicationConfig?.featureFlag?.key,
    });
    identifyFeatureFlagUser();
    setupThirdPartyScripts();
    await initI18n(applicationConfig);
}
export const nonce = getNonce();
export const styleCache = createCache({
    key: "mc-style-cache",
    nonce,
    prepend: true,
});
