import { API_URI_OVERRIDE_STORAGE_KEY, MOCK_AUTH_STORAGE_KEY } from "./application-configuration-storage-keys";
import { deepFreeze, fetchConfig } from "./application-configuration.helpers";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApplicationConfiguration = Record<string, any>;

const proxyTarget = { _config: {} as ApplicationConfiguration };

/**
 * The application configuration.
 */
export const applicationConfig = new Proxy(proxyTarget, {
    get: (target, prop) => target._config[prop as keyof ApplicationConfiguration],
    set: () => false,
}) as ApplicationConfiguration;

export async function loadApplicationConfiguration(): Promise<ApplicationConfiguration> {
    // GET the config file from the deployment and parse it
    const config = await fetchConfig();

    // Allow the API URI to be overridden during testing
    const apiUrlOverride = window?.localStorage?.getItem(API_URI_OVERRIDE_STORAGE_KEY);
    if (apiUrlOverride && config.api.rest.urls.default) {
        config.api.rest.urls.default = apiUrlOverride;
    }
    // Allow the authentication to be overridden during testing
    const shouldAuthBeMocked = /true/i.test(window?.localStorage?.getItem(MOCK_AUTH_STORAGE_KEY) ?? "false");
    if (config?.authentication) {
        config.authentication.shouldBeMocked = shouldAuthBeMocked;
    }

    // Add a timestamp so we know when the config was loaded
    config._updatedAt = Date.now();

    deepFreeze(config);

    proxyTarget._config = config;

    return config;
}

/**
 * Clears the application configuration
 */
export function clearApplicationConfiguration() {
    proxyTarget._config = {};
    return applicationConfig;
}

export function setApplicationConfiguration(applicationConfiguration: ApplicationConfiguration) {
    proxyTarget._config = applicationConfiguration;
}
