export enum PreferencesKey {
    aiAddonEnabled = "ai.add-on.enabled",
    aiDataOptInEnabled = "ai.data-optin.enabled",
    aqemPermissionRestrictRecordsBySite = "aqem.permissions.restrict-records-by-site",
    dateFormat = "DATE_FORMAT",
    language = "LANGUAGE",
    militaryTime = "MILITARY_TIME",
    notificationEmailEnabled = "notification.email.enabled",
    uiDateTimeTimeZoneAuto = "ui.date-time.time-zone-auto",
    uiDateTimeTimeZoneName = "ui.date-time.time-zone-name",
    uiLanguagesLanguage = "ui.languages.language",
    utcOffset = "UTC_OFFSET",
}

export const localStorageUserPreferencesLanguageKey = "mc-user-preferences-language";
